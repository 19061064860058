import { MdClose, MdFilterAltOff } from "react-icons/md";
import React, { useEffect, useState } from "react";
import BACK_END from "../../../config/direction";
import { isMobile } from "react-device-detect";
import { BsCalendar3 } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import Calendar from "react-calendar";
import Dropdown from "react-dropdown";
import "./InventoryExits.css";
import axios from "axios";
import BillDetailModal from "../../../components/BillDetailModal/BillDetailModal";
import { exportToExcel } from "../../../util/excel";
import { RiFileExcel2Fill } from "react-icons/ri";
import { PiTagSimpleFill } from "react-icons/pi";

import { useContext } from 'react';
import { SessionContext } from "../../../hooks/useSession";

const InventoryEntries = () => {
  const { token } = useContext(SessionContext);
  
  const [searchFolio, setSearchFolio] = useState("");
  const [searchExit, setSearchExit] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [rangeDate, setRangeDate] = useState(null);

  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [exitsData, setExitsData] = useState([]);

  const [productsTotal, setProdutsTotal] = useState(0);

  const weekdayLabels = ["D", "L", "M", "M", "J", "V", "S"];

  const [selectedBill, setSelectedBill] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [costTotal, setCostTotal] = useState(0);

  // Clean filters
  const cleanFilters = () => {
    setSearchFolio("");
    setSearchExit("");
    setRangeDate(null);
    setSelectedUser(null);
    setSelectedDepartment(null);
  };

  // Initialize
  // useEffect to fetch users
  useEffect(() => {
    axios
      .get(`${BACK_END}/user/0`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        const data = res.data;
        if (res.status === 200) {
          const dataPackage = data.map((user) => ({
            label: user.username === 'admin' ? 'e-commerce' : user.username,
            value: user.user_id,
          }));
          setUserOptions([{ label: 'Cualquiera*', value: null }, ...dataPackage]);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });

    // Get departments
    axios.get(`${BACK_END}/department`).then((res) => {
      const data = res.data.data;
      if (res.status === 200) {
        const dataPackage = data.map((department) => ({
          label: department.name,
          value: department.id,
        }));
        setDepartmentOptions([{ label: 'Cualquiera*', value: null }, ...dataPackage]);
      }
    });
  }, [token]);

  // Function to fetch filtered data
  const getFilteredData = async () => {
    try {
      const response = await axios.post(`${BACK_END}/bill/filter/0`, {
        searchFolio: searchFolio,
        searchTerm: searchExit,
        userId: selectedUser?.value,
        startDate: rangeDate ? rangeDate[0].toISOString() : null,
        endDate: rangeDate ? rangeDate[1].toISOString() : null,
        department_id: selectedDepartment?.value,
      });

      const { data } = response.data;

      setExitsData(data);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const countSales = async () => {
    try {
      const response = await axios.post(`${BACK_END}/bill/count`, {
        searchFolio: searchFolio,
        searchTerm: searchExit,
        userId: selectedUser?.value,
        startDate: rangeDate ? rangeDate[0].toISOString() : null,
        endDate: rangeDate ? rangeDate[1].toISOString() : null,
        department_id: selectedDepartment?.value,
      });

      const { data } = response.data;

      setCountTotal(data.salesTotal);
      setCostTotal(data.totalCost);
      setProdutsTotal(data.totalPrice);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  // Function to format date
  const formadivate = (isoDate) => {
    if (!isoDate) {
      return null;
    }

    const date = new Date(isoDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    return date.toLocaleDateString("es-MX", options);
  };

  // useEffect to fetch filtered data
  useEffect(() => {
    getFilteredData();
    countSales(); // eslint-disable-next-line
  }, [searchFolio, searchExit, selectedUser, rangeDate, selectedDepartment]);

  // Give a nice format
  const formatNumber = (number) => {
    number = Number(number);
    return number.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const renderRows = () => {
    // Crear un objeto para agrupar los artículos por bill_id
    const groupedItems = exitsData.reduce((acc, item) => {
      if (!acc[`${item.created_at}-${item.bill_id}`]) {
        acc[`${item.created_at}-${item.bill_id}`] = [];
      }
      acc[`${item.created_at}-${item.bill_id}`].push(item);
      return acc;
    }, {});

    // Variable para alternar el color de fondo
    let color = false;

    // Renderizar cada grupo
    return Object.values(groupedItems).map((items, index) => {
      // Alternar el color de fondo para el grupo
      //color = !color;
      const rowClasses = `exits-row ${color ? "inventary-row-color" : ""}`;

      // Crear un div para cada grupo de artículos
      return (
        <>
          <div className="exits-bill-id-header">Ticket #{items[0].bill_id}</div>
          <div
            className={rowClasses}
            key={index}
            onClick={() => setSelectedBill(items[0].bill_id)}
          >
            {items.map((item, itemIndex) => (
              <div key={itemIndex} className="exits-item-row">
                <div className="exits-item">{item.name}</div>
                <div className="exits-item">{formadivate(item.created_at)}</div>
                {!isMobile && (
                  <div className="exits-item">
                    {item.sale_channel === "online"
                      ? "e-commerce"
                      : item.user_name}
                  </div>
                )}
                {!isMobile && (
                  <div className="exits-item">
                    ${formatNumber(Number(item.cost))}
                  </div>
                )}
                {/* {!isMobile && <div>${formatNumber(item.price)}</div>} */}
                <div className="exits-item">
                  $
                  {formatNumber(
                    Number(item.price) -
                    Number(item.price) * (Number(item.discount) / 100)
                  )}
                </div>
                {!isMobile && (
                  <div className="exits-item">
                    {item.refund ? "Devuelto" : item.payment_method}
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      );
    });
  };

  const closeModal = () => {
    setSelectedBill(null);
  };

  const handleExportData = async () => {
    const response = await axios.post(`${BACK_END}/bill/filter/-1`, {
      searchTerm: searchExit,
      userId: selectedUser?.value,
      startDate: rangeDate ? rangeDate[0].toISOString() : null,
      endDate: rangeDate ? rangeDate[1].toISOString() : null,
      department_id: selectedDepartment?.value,
    });

    const { data } = response.data;

    const formattedData = data.map((register) => ({
      "Folio ticket": parseInt(register?.bill_id),
      "Folio producto": parseInt(register?.detail_id),
      Fecha: new Date(register?.created_at),
      "Vendido por": register?.user_name,
      Artículo: register?.name,
      "Precio original": Number(register?.price),
      "% de Descuento": Number(register?.discount),
      "Precio final": Number(
        formatNumber(
          Number(register?.price) -
          Number(register?.price) * (Number(register?.discount) / 100)
        )
      ),
      Apartado: register?.has_debt ? "Si" : "No",
      Reembolsado: register?.refund ? "Si" : "No",
      "Canal de venta":
        register?.sale_channel === "offline"
          ? "Punto de venta"
          : "Tienda en línea",
    }));

    const currentDate = new Date();

    exportToExcel(
      formattedData,
      `Ventas_${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`
    );
  };

  // Render
  return (
    <div className="inventory-container">
      <div className="table-box-container table-box-top-hidden">
        <div className="table-box">
          <div className="table-header">
            <div className="inventory-header" key={'exits-head0'}>Artículo</div>
            <div className="inventory-header" key={'exits-head1'}>Fecha</div>
            {!isMobile && <div className="inventory-header" key={'exits-head2'}>Vendido por</div>}
            {!isMobile && <div className="inventory-header" key={'exits-head3'}>Costo</div>}
            <div className="inventory-header" key={'exits-head4'}>Precio final</div>
            {!isMobile && <div className="inventory-header" key={'exits-head5'}>Tipo de pago</div>}
          </div>
          <div className="exits-table">

            <div className="exits-tbody">{renderRows(exitsData)}</div>
          </div>
        </div>
      </div>
      <div className="exits-totals-container">
        <div className="exits-totals-item"></div>
        <div className="exits-totals-item hidden"></div>
        <div className="exits-totals-item">TOTALES:</div>
        <div className="exits-totals-item hidden">
          $<span id="totalCost">{formatNumber(costTotal)}</span>
        </div>

        <div className="exits-totals-item">
          $<span id="totalPrice">{formatNumber(productsTotal)}</span>
        </div>
        <div className="exits-totals-item hidden">
          Cantidad: <span id="totalStock">{countTotal}</span>
        </div>
      </div>
      <div className="inventory-row-bottom">
        <div className="buttons-row-container2">
          <div className="inventory-search-bar-product">
            <FaSearch className="search-icon" />
            <input
              type="text"
              value={searchFolio}
              onChange={(event) => {
                setSearchFolio(event.target.value);
              }}
              placeholder="Folio"
            />
          </div>
          <div className="inventory-search-bar-product">
            <FaSearch className="search-icon" />
            <input
              type="text"
              value={searchExit}
              onChange={(event) => {
                setSearchExit(event.target.value);
              }}
              placeholder="Artículo"
            />
          </div>
          <div className="buttons-row-container">
            <button
              className="date-entry-button"
              onClick={() => setShowDatePicker(!showDatePicker)}
            >
              <BsCalendar3 size={28} />
              <span className="date-entry-label">Fecha</span>
            </button>
            {showDatePicker && (
              <div className="date-modal-overlay">
                <div className="date-picker-container">
                  <Calendar
                    tileClassName={({ date, view }) => {
                      // Agregar clase de estilo condicional para resaltar el día actual
                      if (
                        view === "month" &&
                        date.toDateString() === new Date().toDateString()
                      ) {
                        return "highlight-today";
                      }
                      return "";
                    }}
                    onChange={(date) => setRangeDate(date)}
                    value={rangeDate}
                    selectRange={true}
                    showNeighboringMonth={false}
                    formatShortWeekday={(locale, date) =>
                      weekdayLabels[date.getDay()]
                    }
                  />
                  <button
                    className="calendar-close-button"
                    onClick={() => setShowDatePicker(false)}
                  >
                    <MdClose size={20} color="black" />
                  </button>
                </div>
              </div>
            )}

            <button className="bottom-button" onClick={cleanFilters}>
              <MdFilterAltOff size={20} color="black" />
            </button>

            <button
              className="bottom-button inventory-export-button"
              onClick={handleExportData}
            >
              <RiFileExcel2Fill size={18} /> Exportar
            </button>
          </div>
        </div>
        <div className="date-entry-container">
          <Dropdown
            className="sales-department-scroll"
            options={userOptions}
            onChange={(text) => setSelectedUser(text)}
            value={selectedUser}
            placeholder={
              <div className="dropdown-placeholder">
                <FaUser className="dropdown-icon" color="black" />
                Usuario
              </div>
            }
          />

          <Dropdown
            className="sales-department-scroll"
            options={departmentOptions}
            onChange={(text) => setSelectedDepartment(text)}
            value={selectedDepartment}
            placeholder={
              <div className="dropdown-placeholder">
                <PiTagSimpleFill className="dropdown-icon" color="black" />
                Departamento
              </div>
            }
          />
        </div>
      </div>

      <BillDetailModal
        bill_id={selectedBill}
        closeModal={closeModal}
      />
    </div>
  );
};

export default InventoryEntries;
