import React, { useState, useEffect } from 'react';
import BACK_END from '../../config/direction';
import { MdClose } from 'react-icons/md';
import axios from 'axios';

const DeliveryDetailModal = ({ showDetailsModal, closeDetailModal, bill_id, userJWT }) => {

    const [orderDetails, setOrderDetails] = useState([]);
    const [billDetails, setBillDetails] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const fetchBillDetails = async () => {
            try {
                const response = await axios.get(`${BACK_END}/bill?bill_id=${bill_id}`, {
                    headers: {
                        Authorization: `Bearer ${userJWT}`,
                    },
                });
                const data = response.data[0];
                setBillDetails(data);
            } catch (error) {
                console.error('Error fetching bill details:', error);
            }
        };

        if (showDetailsModal) {
            fetchBillDetails();
        } // eslint-disable-next-line
    }, [showDetailsModal, bill_id]);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const responseBill = await axios.get(`${BACK_END}/bill/order?bill_id=${bill_id}`, {
                    headers: {
                        Authorization: `Bearer ${userJWT}`,
                    },
                });
                setOrderDetails(responseBill?.data);
                const responseUser = await axios.get(`${BACK_END}/bill/user?ecommerce_user_id=${billDetails?.ecommerce_user_id}`, {
                    headers: {
                        Authorization: `Bearer ${userJWT}`,
                    },
                });
                setUserInfo(responseUser?.data);
            } catch (error) {
                console.error('Error fetching order details:', error);
            }
        };

        if (showDetailsModal) {
            fetchOrderDetails();
        } // eslint-disable-next-line
    }, [billDetails]);

    return (
        <>
            {showDetailsModal && (
                <div className="details-modal">
                    <div className="details-modal-container">

                        <h2 className="modal-titulo">DETALLES DEL PEDIDO #{bill_id}</h2>
                        <br />

                        <div className="detalles-uno">
                            <h3 className="titulo-direccion">ARTÍCULOS ({orderDetails.length})</h3>
                            <div className='delivery-container-products'>
                                {
                                    orderDetails.map(product =>
                                        <div className='delivery-row-product'>
                                            <img src={`${BACK_END}/product/image/${product?.image}`} className='delivery-list-img' />
                                            <div className='delivery-column-products'>
                                                <p>{product?.name}</p>
                                                <p>{product?.product_id}</p>
                                                <p>${product?.priceDetail}</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="detalles-dos">
                            <br />
                            <h3 className="titulo-direccion">UBICACIÓN</h3>
                            <div className="col-1">
                                <p className="descripcion-direccion">DIRECCIÓN</p>
                                <input type="text" id="inputCiudad" className="input-texto full-width" value={billDetails?.shipping_address} disabled={true} />
                            </div>
                            <div className="detalles-dos-input">
                                <div className="col-1">
                                    <p className="descripcion-direccion">C. POSTAL</p>
                                    <input type="text" id="inputCodigoPostal" value={(billDetails?.shipping_address) ? billDetails?.shipping_address.split(',').pop() : 'N/A'} className="input-texto full-width" disabled={true} />
                                </div>

                                <div className="col-2">
                                    <p className="descripcion-direccion">NÚMERO DE SEGUIMIENTO</p>
                                    <input type="text" id="inputDatos" value={billDetails?.shipping_tracking_number ?? 'N/A'} class="input-texto full-width" disabled={true} />
                                </div>

                            </div>

                        </div>
                        <div className="detalles-tres">
                            <br />
                            <h3 className="titulo-direccion">CLIENTE</h3>
                            <div className="detalles-tres-input">
                                <div className="detalles-col-1">
                                    <p className="descripcion-direccion">NOMBRE</p>
                                    <input type="text" id="inputNombre" value={userInfo?.name ?? 'N/A'} className="input-texto full-width" disabled={true} />
                                </div>

                                <div className="detalles-col-2">
                                    <p className="descripcion-direccion">TELÉFONO</p>
                                    <input type="text" id="inputTelefono" value={userInfo?.phone_number ?? 'N/A'} className="input-texto full-width" disabled={true} />
                                </div>

                            </div>

                        </div>

                        <button className='details-close-button1' onClick={closeDetailModal}>
                            <MdClose size={20} color='white' />
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default DeliveryDetailModal;