import { MdClose, MdFilterAltOff } from "react-icons/md";
import React, { useEffect, useState } from "react";
import BACK_END from "../../../config/direction";
import { isMobile } from "react-device-detect";
import { BsCalendar3 } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import Calendar from "react-calendar";
import Dropdown from "react-dropdown";
import "./InventoryMovements.css";
import axios from "axios";
import BillDetailModal from "../../../components/BillDetailModal/BillDetailModal";
import { exportToExcel } from "../../../util/excel";
import { RiFileExcel2Fill } from "react-icons/ri";
import CashDepositModal from "../../../components/CashDepositModal/CashDepositModal";
import CashWithdrawalModal from "../../../components/CashWithdrawalModal/CashWithdrawalModal";
import CustomerPaymentsModal from "../../../components/CustomerPaymentsModal/CustomerPaymentsModal";
import { PiTagSimpleFill } from "react-icons/pi";
import { FaBook } from "react-icons/fa6";

import { useContext } from 'react';
import { SessionContext } from "../../../hooks/useSession";

const InventoryMovements = () => {
  const { token } = useContext(SessionContext);
  const now = new Date();

  const [searchFolio, setSearchFolio] = useState("");
  const [searchExit, setSearchExit] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [rangeDate, setRangeDate] = useState([
    new Date(now.setHours(0, 0, 0, 0)),
    new Date(now.setHours(23, 59, 59, 999))
  ]);

  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const typeMovmentOptions = [
    { label: 'Cualquiera*', value: null },
    "Venta",
    "Abono",
    "Cancelado",
    "Reembolso",
    "Ingreso de efectivo a caja",
    "Retiro de efectivo a caja",
  ];
  const [selectedTypeMovmentOptions, setSelectedTypeMovmentOptions] = useState(null);

  const [exitsData, setExitsData] = useState([]);

  const [totalMoney, setTotalMoney] = useState(0);

  const weekdayLabels = ["D", "L", "M", "M", "J", "V", "S"];

  const [selectedMovment, setSelectedMovment] = useState(null);

  // Handle modals
  const handleModals = (movment) => {

    let movmentData = null;

    switch (movment.transaction_type) {
      case 'Venta':
        movmentData = { transactionType: 'Venta', bill_id: movment?.bill_id };
        break;
      case 'Reembolso':
        movmentData = { transactionType: 'Reembolso', bill_id: movment?.bill_id };
        break;
      case 'Abono':
        movmentData = { transactionType: 'Abono', customer_id: movment?.customer_id };
        break;
      case 'Cancelado':
        movmentData = { transactionType: 'Cancelado', bill_id: movment?.bill_id };
        break;
      case 'Ingreso de efectivo a caja':
        movmentData = { transactionType: 'Ingreso de efectivo a caja', id: movment.id };
        break;
      case 'Retiro de efectivo a caja':
        movmentData = { transactionType: 'Retiro de efectivo a caja', id: movment.id };
        break;
      default:
        break;
    }

    setSelectedMovment(movmentData);
  }

  // Clean filters
  const cleanFilters = () => {
    setSearchFolio("");
    setSearchExit("");

    //setRangeDate(null);
    const now = new Date();
    const startOfDay = new Date(now.setHours(0, 0, 0, 0));
    const endOfDay = new Date(now.setHours(23, 59, 59, 999));
    setRangeDate([startOfDay, endOfDay]);

    setSelectedUser(null);
    setSelectedDepartment(null);
    setSelectedTypeMovmentOptions(null);
  };

  // Initialize
  // useEffect to fetch users
  useEffect(() => {

    // Get users
    axios
      .get(`${BACK_END}/user/0`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        const data = res.data;
        if (res.status === 200) {
          const dataPackage = data.map((user) => ({
            label: user.username === 'admin' ? 'e-commerce' : user.username,
            value: user.user_id,
          }));
          setUserOptions([{ label: 'Cualquiera*', value: null }, ...dataPackage]);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });

    // Get departments
    axios.get(`${BACK_END}/department`).then((res) => {
      const data = res.data.data;
      if (res.status === 200) {
        const dataPackage = data.map((department) => ({
          label: department.name,
          value: department.id,
        }));
        setDepartmentOptions([{ label: 'Cualquiera*', value: null }, ...dataPackage]);
      }
    });
  }, [token]);

  // Function to fetch filtered data
  const getFilteredData = async () => {
    try {
      const response = await axios.get(`${BACK_END}/movments/filter`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          searchFolio: searchFolio,
          searchTerm: searchExit,
          userId: selectedUser?.value,
          startDate: rangeDate ? rangeDate[0].toISOString() : null,
          endDate: rangeDate ? rangeDate[1].toISOString() : null,
          department_id: selectedDepartment?.value,
          transactionType: selectedTypeMovmentOptions?.value,
        },
      });

      const { data } = response.data;

      setExitsData(data);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const countSales = async () => {
    try {
      const response = await axios.get(`${BACK_END}/movments/total`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          searchFolio: searchFolio,
          searchTerm: searchExit,
          userId: selectedUser?.value,
          startDate: rangeDate ? rangeDate[0].toISOString() : null,
          endDate: rangeDate ? rangeDate[1].toISOString() : null,
          department_id: selectedDepartment?.value,
          transactionType: selectedTypeMovmentOptions?.value,
        }
      });
      const { data } = response;
      setTotalMoney(data.totalSum);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  // Function to format date
  // Function to format date or time
  const formatDateOrTime = (isoDate) => {
    if (!isoDate) return 'N/A';

    const date = new Date(isoDate);

    // Check if the date is the same as the selected date range
    const startDate = rangeDate ? rangeDate[0].toDateString() : null;
    const endDate = rangeDate ? rangeDate[1].toDateString() : null;

    if (startDate === endDate && startDate && endDate) {
      // Show time if the date is the same as the selected date range
      return date.toLocaleTimeString("es-MX", { hour: "2-digit", minute: "2-digit" });
    } else {
      // Show date if it's different
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return date.toLocaleDateString("es-MX", options);
    }
  };

  // Function to format the selected date range
  const formatSelectedDateRange = () => {
    if (!rangeDate || rangeDate.length !== 2) return "";

    const [startDate, endDate] = rangeDate;

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    const startDateStr = startDate.toLocaleDateString("es-MX", options);
    const endDateStr = endDate.toLocaleDateString("es-MX", options);

    return startDateStr === endDateStr ? startDateStr : `${startDateStr} - ${endDateStr}`;
  };

  // useEffect to fetch filtered data
  useEffect(() => {
    getFilteredData();
    countSales(); // eslint-disable-next-line
  }, [
    searchFolio,
    searchExit,
    selectedUser,
    rangeDate,
    selectedDepartment,
    selectedTypeMovmentOptions,
  ]);

  // Give a nice format
  const formatNumber = (number) => {
    number = Number(number);
    return number.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const renderRows = () => {
    let currentBillId = null;

    return exitsData.map((item, index) => {
      if (currentBillId !== item.bill_id) {
        currentBillId = item.bill_id;
      }
      const rowClasses = `movements-row ${item?.total > 0
        ? "row-verde"
        : item?.total < 0
          ? "row-rojo" // eslint-disable-next-line
          : item?.transaction_type === 'Venta' && item?.total == 0
            ? "row-amarillo"
            : ""
        }`;

      return (
        <div
          className={rowClasses}
          key={`movment-${index}`}
          onClick={() => handleModals(item)}
        >
          <div className="movments-item">
            {item?.transaction_type} {item?.bill_id ? `#${item?.bill_id}` : ""}
          </div>
          {!isMobile && (
            <div className="movments-item">{item?.product_name ?? "N/A"}</div>
          )}
          <div className="movments-item">{formatDateOrTime(item?.created_at)}</div>
          {!isMobile && (
            <div className="movments-item">
              {item?.sale_channel === "online" ? "e-commerce" : item?.user_name}
            </div>
          )}
          {!isMobile && (
            <div className="movments-item">{item?.total < 0 ? '-' : ''}${formatNumber(Math.abs(item?.total))}</div>
          )}
        </div>
      );
    });
  };

  const closeModal = () => {
    setSelectedMovment(null);
  };

  const handleExportData = async () => {
    const response = await axios.get(`${BACK_END}/movments/filter`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        searchFolio,
        searchTerm: searchExit,
        userId: selectedUser?.value,
        startDate: rangeDate ? rangeDate[0].toISOString() : null,
        endDate: rangeDate ? rangeDate[1].toISOString() : null,
        department_id: selectedDepartment?.value,
        transactionType: selectedTypeMovmentOptions?.value,
      },
    });

    const { data } = response.data;

    const formattedData = data.map((register) => ({
      "Fecha": new Date(register?.created_at),
      "Movimiento": register?.transaction_type,
      "Folio": (register?.bill_id) ? parseInt(register?.bill_id) : 'N/A',
      "Artículo": register?.product_name,
      "Realizado por": register?.user_name,
      "Total": (register?.transaction_type === 'Retiro de efectivo a caja') ? -Number(register?.total) : Number(register?.total),
    }));

    const currentDate = new Date();

    exportToExcel(
      formattedData,
      `Movimientos_${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`
    );
  };

  // Render
  return (
    <div className="inventory-container">
      <div className="table-box-container table-box-top-hidden">
        <div className="table-box">
          <div className="exits-table">
            <div className="table-header">
              <div className="inventory-header">Movimiento</div>
              {!isMobile && <div className="inventory-header">Artículo</div>}
              <div className="inventory-header">Hora</div>
              {!isMobile && <div className="inventory-header">Usuario</div>}
              {!isMobile && <div className="inventory-header">Total</div>}
            </div>
            <div className="exits-tbody">{renderRows(exitsData)}</div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="totals-container">
          <div className="totals-item totals-item-large">Fecha(s): <span className="text-yellow">{formatSelectedDateRange()}</span></div>

          <div className="totals-item">BALANCE TOTAL:</div>
          <div className="totals-item">
            $<span id="totalPrice">{formatNumber(totalMoney)}</span>
          </div>
        </div>
      )}
      <div className="inventory-row-bottom">
        <div className="buttons-row-container2">
          <div className="inventory-search-bar-product">
            <FaSearch className="search-icon" />
            <input
              type="text"
              value={searchFolio}
              onChange={(event) => setSearchFolio(event.target.value)}
              placeholder="Folio"
            />
          </div>
          <div className="inventory-search-bar-product">
            <FaSearch className="search-icon" />
            <input
              type="text"
              value={searchExit}
              onChange={(event) => setSearchExit(event.target.value)}
              placeholder="Artículo"
            />
          </div>
          <div className="buttons-row-container">
            <button
              className="date-entry-button"
              onClick={() => setShowDatePicker(!showDatePicker)}
            >
              <BsCalendar3 size={28} />
              <span className="date-entry-label">Fecha</span>
            </button>
            {showDatePicker && (
              <div className="date-modal-overlay">
                <div className="date-picker-container">
                  <Calendar
                    tileClassName={({ date, view }) => {
                      // Agregar clase de estilo condicional para resaltar el día actual
                      if (
                        view === "month" &&
                        date.toDateString() === new Date().toDateString()
                      ) {
                        return "highlight-today";
                      }
                      return "";
                    }}
                    onChange={(date) => setRangeDate(date)}
                    value={rangeDate}
                    selectRange={true}
                    showNeighboringMonth={false}
                    formatShortWeekday={(locale, date) =>
                      weekdayLabels[date.getDay()]
                    }
                  />
                  <button
                    className="calendar-close-button"
                    onClick={() => setShowDatePicker(false)}
                  >
                    <MdClose size={20} color="black" />
                  </button>
                </div>
              </div>
            )}
            <button className="bottom-button" onClick={cleanFilters}>
              <MdFilterAltOff size={20} color="black" />
            </button>

            <button
              className="bottom-button inventory-export-button"
              onClick={handleExportData}
            >
              <RiFileExcel2Fill size={18} /> Exportar
            </button>
          </div>
        </div>

        <div className="date-entry-container">
          <Dropdown
            className="sales-department-scroll"
            options={userOptions}
            onChange={(text) => setSelectedUser(text)}
            value={selectedUser}
            placeholder={
              <div className="dropdown-placeholder">
                <FaUser className="dropdown-icon" color="black" />
                Usuario
              </div>
            }
          />

          <Dropdown
            className="sales-department-scroll"
            options={departmentOptions}
            onChange={(text) => setSelectedDepartment(text)}
            value={selectedDepartment}
            placeholder={
              <div className="dropdown-placeholder">
                <PiTagSimpleFill className="dropdown-icon" color="black" />
                Departamento
              </div>
            }
          />

          <Dropdown
            className="sales-department-scroll"
            options={typeMovmentOptions}
            onChange={(text) => setSelectedTypeMovmentOptions(text)}
            value={selectedTypeMovmentOptions}
            placeholder={
              <div className="dropdown-placeholder">
                <FaBook className="dropdown-icon" color="black" />
                Movimiento
              </div>
            }
          />
        </div>
      </div>

      {
        (
          selectedMovment?.transactionType === 'Venta' ||
          selectedMovment?.transactionType === 'Reembolso' ||
          selectedMovment?.transactionType === 'Cancelado'
        ) &&
        <BillDetailModal
          bill_id={selectedMovment?.bill_id}
          closeModal={closeModal}
        />
      }

      {
        selectedMovment?.transactionType === 'Ingreso de efectivo a caja' &&
        <CashDepositModal
          isCashDepositModalOpen={true}
          closeCashDepositModal={closeModal}
          showRegisterScreen={false}
        />
      }

      {
        selectedMovment?.transactionType === 'Retiro de efectivo a caja' &&
        <CashWithdrawalModal
          isCashWithdrawalModalOpen={true}
          closeCashWithdrawalModal={closeModal}
          showRegisterScreen={false}
        />
      }

      {
        selectedMovment?.transactionType === 'Abono' &&
        <CustomerPaymentsModal
          customer_id={selectedMovment?.customer_id}
          onRequestClose={closeModal}
        />
      }

    </div>
  );
};

export default InventoryMovements;