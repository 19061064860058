import UtilityContainer from '../UtilityContainer/UtilityContainer';
import React, { useState, useEffect } from 'react';
import BACK_END from '../../../config/direction';
import { PieChart } from '@mui/x-charts';
import Paper from "@mui/material/Paper";
import './UtilityStock.css';
import axios from 'axios';

import { exportToExcel } from '../../../util/excel';

import { useContext } from 'react';
import { SessionContext } from '../../../hooks/useSession';

const UtilityStock = () => {
    const { token } = useContext(SessionContext);

    const [dataset, setDataset] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [stockValue, setStockValue] = useState(0);
    const groupLimit = 6;

    // Custom color palette
    const colors = [
        "#FF6384", // Rojo suave
        "#36A2EB", // Azul suave
        "#FFCE56", // Amarillo suave
        "#4BC0C0", // Turquesa
        "#9966FF", // Morado suave
        "#FF9F40", // Naranja suave
        "#F7464A", // Rojo fuerte
        "#46BFBD", // Verde azulado
        "#FDB45C", // Amarillo fuerte
        "#949FB1", // Gris azulado
        "#4D5360", // Gris oscuro
        "#AC64AD"  // Morado fuerte
    ];

    const formatNumber = (number) => {
        number = Number(number);
        if (number >= 1000000) {
            return (number / 1000000).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'k';
        } else {
            return number.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    };

    const getData = () => {
        axios.get(`${BACK_END}/stadistics/departament-stock`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((res) => {
                const data = res.data;
                if (res.status === 200) {
                    // Ordenar los datos de mayor a menor
                    const sortedData = data.sort((a, b) => b.totalPrice - a.totalPrice);

                    // Separar los datos para el gráfico y la exportación
                    let othersStock = 0;
                    let othersPrice = 0;
                    let formattedData = sortedData.map((element, index) => ({
                        id: index.toString(),
                        value: Number(element.percentage.replace('%', '')),
                        label: `${element.department} $${formatNumber(element.totalPrice)}`,
                        price: Number(element.totalPrice)
                    }));

                    let chartData = formattedData;
                    if (formattedData.length > groupLimit) {
                        const topDepartments = formattedData.slice(0, groupLimit);
                        const others = formattedData.slice(groupLimit);

                        others.forEach(item => {
                            othersStock += Number(item.value);
                            othersPrice += Number(item.price);
                        });

                        topDepartments.push({
                            id: groupLimit.toString(),
                            value: Number(othersStock.toFixed(2)),
                            label: `Otros $${formatNumber(othersPrice)}`,
                            price: othersPrice
                        });

                        chartData = topDepartments;
                    }

                    const yChart = [{
                        arcLabel: (item) => (item.value > 0) ? `${item.value}%` : '',
                        data: chartData,
                        innerRadius: 70,
                    }];

                    setDataset(yChart);
                    setExportData(formattedData);
                }
            })
            .catch((error) => console.error(error));

        axios.get(`${BACK_END}/stadistics/inventory-value`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((res) => {
                const data = res.data;
                if (res.status === 200) {
                    setStockValue(data.totalPrice);
                }
            })
            .catch((error) => console.error(error));
    }

    useEffect(getData, [token]);

    const handleExportClick = () => {
        let data = [];
        exportData.forEach(row => {
            data.push({ 'Departamento': row.label.split(' ')[0], 'Porcentaje': row.value + "%", 'Precio total': Number(row.price) });
        });
        exportToExcel(data, 'Stock por Departamento');
    };

    const header = (
        <div className='utility-header'>
            Precio por departamento
        </div>
    );

    const CustomItemTooltipContent = (props) => {
        const { series, dataIndex, axisValue } = props;
        return (
            <Paper sx={{ padding: 1, backgroundColor: 'white' }}>
                <p>{axisValue}</p>
                <p>$ {series[0]?.data[dataIndex]?.price.toFixed(2)}</p>
            </Paper>
        );
    };

    const chart = (
        <div className='utility-payment-content chart-content-father utility-stock-father'>
            {dataset.length > 0 ? (
                <PieChart
                    sx={{ width: "100%", maxWidth: 600, maxHeight: 300 }}
                    margin={{ right: 160 }}
                    series={dataset}
                    width={600}
                    height={270}
                    colors={colors}
                    tooltip={{ axisContent: CustomItemTooltipContent, itemContent: CustomItemTooltipContent }}
                />
            ) : <></>}
            <div className='utility-stock-total'>
                Total: ${formatNumber(stockValue)}
            </div>
        </div>
    );

    return (
        <UtilityContainer
            header={header}
            content={chart}
            handleExportClick={handleExportClick}
        />
    );
}

export default UtilityStock;