import React, { useEffect, useState } from 'react';
import UtilityContainer from '../UtilityContainer/UtilityContainer';
import Dropdown from 'react-dropdown';
import axios from 'axios';
import BACK_END from '../../../config/direction';
import { LineChart } from '@mui/x-charts';
import Paper from "@mui/material/Paper";
import { exportToExcel } from '../../../util/excel';

import './UtilityMonth.css';

import { useContext } from 'react';
import { SessionContext } from '../../../hooks/useSession';

const UtilityMonth = () => {
    const { token } = useContext(SessionContext);

    const options = ['Facturación', 'Ganancias', 'Ventas'];
    const [selectedOption, setSelectedOption] = useState({ label: options[0], value: options[0] });
    const [chartData, setChartData] = useState(null);
    const [xAxis, setXAxis] = useState([]);
    const [yMin, setYMin] = useState(0);
    const [yMax, setYMax] = useState(100);
    const [series, setSeries] = useState([]); // eslint-disable-next-line
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 550);

    const monthsFull = [
        { label: "Enero", value: 0 },
        { label: "Febrero", value: 1 },
        { label: "Marzo", value: 2 },
        { label: "Abril", value: 3 },
        { label: "Mayo", value: 4 },
        { label: "Junio", value: 5 },
        { label: "Julio", value: 6 },
        { label: "Agosto", value: 7 },
        { label: "Septiembre", value: 8 },
        { label: "Octubre", value: 9 },
        { label: "Noviembre", value: 10 },
        { label: "Diciembre", value: 11 }
    ];

    const currentYear = new Date().getFullYear();

    const generateYearsArray = () => {
        const years = [];
        for (let year = 2024; year <= currentYear; year++) {
            years.push({ label: year, value: year });
        }
        return years;
    }

    const years = generateYearsArray();
    const [selectedYear, setSelectedYear] = useState(years[0]);
    const currentMonth = new Date().getMonth();
    const months = monthsFull.filter(month => month.value <= currentMonth);
    const [seletedMonth, setSelectedMonth] = useState(months[currentMonth]);

    const getData = () => {
        const getTimezoneOffset = () => {
            const offset = new Date().getTimezoneOffset();
            const absoluteOffset = Math.abs(offset);
            const hours = Math.floor(absoluteOffset / 60);
            const minutes = absoluteOffset % 60;
            const sign = offset <= 0 ? '+' : '-';
            return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        };

        const userTimezone = getTimezoneOffset();

        axios.get(`${BACK_END}/stadistics/sales-by-day?timezone=${userTimezone}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: { month: seletedMonth.value + 1, year: selectedYear.value }
        })
            .then((res) => {
                const data = res.data;
                if (res.status === 200) {
                    setChartData(data);
                    const monthDays = new Date(selectedYear.value, seletedMonth.value + 1, 0).getDate();
                    const xChart = Array.from({ length: monthDays }, (_, i) => i + 1);
                    let yChart = data.map(dayData => Number(dayData.totalProfit));
                    setYMin(Math.min(...yChart));
                    setYMax(Math.max(...yChart));
                    setXAxis([{ data: xChart, label: 'Día', dataKey: 'x' }]);
                    setSeries([{ data: yChart, area: true }]);
                }
            })
            .catch((error) => console.error(error));
    }

    const handleSelection = () => {
        if (!chartData) return;
        let yChart = [];
        if (selectedOption.value === 'Facturación') {
            yChart = chartData.map(dayData => dayData.totalSales);
        } else if (selectedOption.value === 'Ganancias') {
            yChart = chartData.map(dayData => dayData.totalProfit);
        } else {
            yChart = chartData.map(dayData => dayData.totalProductsSold);
        }
        setYMin(Math.min(...yChart));
        setYMax(Math.max(...yChart));
        setSeries([{ data: yChart, area: true }]);
    }

    useEffect(getData, [seletedMonth, selectedYear?.value, token]);
    useEffect(handleSelection, [selectedOption, chartData]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 550);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleExportClick = () => {
        const data = chartData.map((row) => ({
            'Fecha': row.date,
            'Numero de ventas': row.totalProductsSold,
            'Total de ventas': Number(row.totalSales),
            'Ganancia': Number(row.totalProfit)
        }));
        exportToExcel(data, 'Ventas del mes');
    };

    const header = (
        <div className='utility-month-header'>
            <Dropdown
                className='utility-scroll utility-day-scroll'
                options={options}
                onChange={(text) => setSelectedOption(text)}
                value={selectedOption}
                placeholder='Ganancia'
            />
            <p className='utility-hidden-text'>de</p>
            <Dropdown
                className='utility-scroll utility-month-scroll'
                options={months}
                onChange={(text) => setSelectedMonth(text)}
                value={seletedMonth}
                placeholder='Mes'
            />
            <p className='utility-hidden-text'>del</p>
            <Dropdown
                className='utility-scroll utility-year-scroll'
                options={years}
                onChange={(text) => setSelectedYear(text)}
                value={selectedYear}
                placeholder='año'
            />

        </div>
    );

    const chart = (
        <LineChart className='utility-month-chart'
            sx={{ width: "100%", maxWidth: 580, maxHeight: 300 }}
            xAxis={xAxis}
            yAxis={[
                {
                    min: yMin,
                    max: yMax,
                    valueFormatter: (element) => selectedOption.value !== 'Ventas' ? `$${element?.toFixed(0)}` : `${element?.toFixed(0)}`,
                },
            ]}
            series={series}
            //width={600}
            //height={300}
            tooltip={{
                trigger: "axis",
                axisContent: (props) => {
                    const { series, dataIndex } = props;
                    const day = xAxis[0]?.data[dataIndex];
                    return (
                        <Paper sx={{ padding: 1, backgroundColor: 'white' }}>
                            <p>Día: {day} de {seletedMonth?.label}</p>
                            <p>{selectedOption.value === 'Ventas' ? series[0]?.data[dataIndex] : `$ ${Number(series[0]?.data[dataIndex]).toFixed(2)}`}</p>
                        </Paper>
                    );
                },
            }}
        />
    );

    return (
        <UtilityContainer
            header={header}
            content={chart}
            handleExportClick={handleExportClick}
        />
    );
}

export default UtilityMonth;
