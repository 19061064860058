import DatePicker, { registerLocale } from "react-datepicker";
import { SessionContext } from "../../hooks/useSession";
import { useState, useContext, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from "react-confirm-alert";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaLock, FaUser } from "react-icons/fa6";
import { FaRegCreditCard } from "react-icons/fa";
import { HiShoppingCart } from "react-icons/hi";
import BACK_END from "../../config/direction";
import { FaInfoCircle } from "react-icons/fa";
import { printCut } from "../../util/pos_esc";
import { BsCashCoin } from "react-icons/bs";
import { GiClothes } from "react-icons/gi";
import { toast } from "react-toastify";
import es from "date-fns/locale/es";

import "./Cut.css";
import SectionNavbar from "../../components/SectionNavbar/SectionNavbar";
import CutDetailModal from "../../components/CutDetailModal/CutDetailModal";

import { CirclesWithBar } from "react-loader-spinner";
import { exportToExcel } from "../../util/excel";

const Cut = () => {
  registerLocale("es", es);

  // user's data
  const { userData, token } = useContext(SessionContext);
  const { initialMoney, setInitialMoney, logout } = useContext(SessionContext);

  const [registerCash, setRegisterCash] = useState(
    localStorage.getItem("cutCash") || ""
  );
  const [cutState, setCutState] = useState("empty");
  const [total, setTotal] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [showData, setShowData] = useState(localStorage.getItem("cutCash"));
  const [selectedCut, setSelectedCut] = useState(null);

  // Cut's data
  const [currentCutData, setCurrentCutData] = useState(null);

  const [selectedOption, setSelectedOption] = useState(0);
  const [cutRegisters, setCutRegisters] = useState([]);

  const [selectedDate, setSelectedDate] = useState([new Date(), null]);

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  // Función para manejar el cambio de fecha
  const handleDateChange = (date) => {
    // Obtener inicio del mes
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    // Obtener fin del mes sumando 1 mes y restando 1 día al inicio del siguiente mes
    const endDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0,
      23,
      59,
      59,
      999
    );
    //console.log([startDate, endDate])

    setSelectedDate([startDate, endDate]);
  };

  const navbarData = [
    { name: "Corte", link: "#" },
    { name: "Historial", link: "#" },
    { name: "Exportar", link: "#" },
  ];

  const toastySuccess = (msg) => {
    toast.success(`${msg}`, { autoClose: 1800, hideProgressBar: true });
  };

  const toastyError = (msg) => {
    toast.error(`${msg}`);
  };

  const checkTotal = () => {
    let usersTotal = Number(registerCash);
    let cutResult =
      Number(currentCutData?.totalCashInBox) === usersTotal
        ? "exact"
        : Number(currentCutData?.totalCashInBox) > usersTotal
          ? "missing"
          : "spare";
    setCutState(cutResult);
    setTotal(usersTotal);
  };

  const handleDownload = () => {
    setIsLoadingExcel(true);

    const startDate = selectedDate[0].toISOString();
    const endDate = selectedDate[1].toISOString();

    fetch(`${BACK_END}/cut/between?startDate=${startDate}&endDate=${endDate}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(async (res) => {
        const data = await res.json();

        const formattedData = data.map((register) => ({
          ID: register.cut_id,
          Fecha: new Date(register.created_at),
          Usuario: register.userName,
          "Dinero inicial en caja": register.initialCash,
          "Ventas con efectivo": register.totalCashPaid,
          "Ventas con tarjeta": register.totalCardPaid,
          "Abonos con efectivo": register.totalDebtCash,
          "Abonos con tarjeta": register.totalDebtCard,
          "Entrada de efectivo en caja": register.totalCashIn,
          "Salida de efectivo en caja": register.totalCashOut,
          "Total de ventas": register.totalSalesMoney,
          "Dinero en caja": register.totalCashInBox,
          "Recuento manual": Number(register.total),
          "Faltante en caja": register.missing,
          "Sobrante en caja": register.spare,
          Ganancia: register.totalGain,
        }));

        if (res.ok) {
          exportToExcel(
            formattedData,
            `Cortes_${selectedDate[0].getMonth() + 1
            }-${selectedDate[0].getFullYear()}`
          );
        } else {
          console.error("Error al descargar datos:", res.statusText);
        }
      })
      .catch((error) => {
        console.error("Error en la solicitud:", error);
      })
      .finally(() => {
        setIsLoadingExcel(false);
      });
  };

  const saveCut = () => {
    if (!showData) {
      toast.info("Falta el recuento manual", {
        autoClose: 2000,
        hideProgressBar: true,
      });
      return;
    }

    const saveFunction = () => {
      let formData = {};
      let usersTotal = Number(registerCash);

      switch (cutState) {
        case "exact":
          formData = { total: total, state: cutState };
          break;
        case "missing":
          const missing = Number(currentCutData?.totalCashInBox) - usersTotal;
          formData = { total: total, missing: missing, state: cutState };
          break;
        default:
          const spare = usersTotal - Number(currentCutData?.totalCashInBox);
          formData = { total: total, spare: spare, state: cutState };
      }

      formData.user_id = userData.user_id;
      formData.initialMoney = initialMoney;

      fetch(`${BACK_END}/cut/`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(formData),
      }).then(async (res) => {
        const data = await res.json();
        //const products = data?.resultProducts;
        const cutData = {
          ...currentCutData,
          created_at: new Date().toISOString(),
          user_name: userData.name,
        };
        if (res.ok) {
          printCut(cutData);
          setRegisterCash(0);
          localStorage.removeItem("cutCash");
          toastySuccess("Corte elaborado exitosamente");
        } else {
          toastyError(data.error);
          console.error(res.error);
        }
      });
    };

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="overlay">
            <div className="custom-ui">
              <h1>Confirmación de corte</h1>
              <p>¿Desea cerrar sesión después de realizar el corte?</p>
              <div className="custom-ui-buttons">
                <button onClick={onClose}>Cancelar</button>
                <button
                  onClick={() => {
                    saveFunction();
                    sessionStorage.removeItem("selectedNavbarOption");
                    setInitialMoney(null);
                    onClose();
                  }}
                >
                  No
                </button>
                <button
                  onClick={() => {
                    saveFunction();
                    logout();
                    setInitialMoney(null);
                    onClose();
                  }}
                >
                  Si
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const closeModal = () => {
    setSelectedCut(null);
  };

  const openCashDrawer = () => {
    fetch(`${BACK_END}/cut/open-box`, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }).catch((error) => console.error(error));
  };

  const getData = () => {
    openCashDrawer();
    fetch(`${BACK_END}/cut/cut-data`, {
      method: "POST",
      mode: "cors",
      cache: "default",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ initialCash: initialMoney }),
    })
      .then(async (response) => {
        const res = await response.json();
        if (response.ok) {
          setCurrentCutData(res);
          //console.log(res)
        }
      })
      .catch((error) => console.error(error));
  };

  const formatNumber = (number) => {
    number = Number(number);
    return number.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // eslint-disable-next-line
  useEffect(() => {
    handleDateChange(new Date());
    getData(); // eslint-disable-next-line
  }, []); // eslint-disable-next-line
  useEffect(checkTotal, [registerCash, currentCutData]);

  useEffect(() => {
    if (selectedOption === 1) {
      fetch(`${BACK_END}/cut`, {
        method: "GET",
        mode: "cors",
        cache: "default",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      })
        .then(async (response) => {
          const res = await response.json();
          if (response.ok) {
            setCutRegisters(res);
          }
        })
        .catch((error) => console.error(error));
    } // eslint-disable-next-line
  }, [selectedOption]);

  const handleInput = (event, setValue) => {
    const input = event.target.value;
    if (/^\d*\.?\d{0,2}$/.test(input)) {
      setValue(input);
    }
  };

  const handleEnter = () => {
    if (registerCash !== "") {
      setShowData(true);
      localStorage.setItem("cutCash", registerCash);
    }
  };

  const formatDateTime = (isoDateTime) => {
    if (!isoDateTime) {
      return null;
    }

    const dateTime = new Date(isoDateTime);

    return dateTime.toLocaleString(undefined, {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  //Date for top row
  const formatDate = (date) => {
    const daysOfWeek = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const monthsOfYear = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const dayName = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const monthName = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    return `${dayName} ${day} de ${monthName} del ${year}`;
  };

  const today = new Date();
  const formattedDate = formatDate(today);

  // Render
  return (
    <>
      <div className="cut-container">
        <Navbar />
        <div className="cut__card_container">
          <div className="cut__box">
            <SectionNavbar
              navbarData={navbarData}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              inverseColor={true}
            />
            {selectedOption === 0 ? (
              <div className="cut__card">
                <div className="cut__header">
                  <div className="cut__header__title">
                    <FaUser />
                    <p>
                      {userData?.name.length > 24 ? (
                        <p>{userData?.name.substring(0, 20)}...</p>
                      ) : (
                        <p>{userData?.name}</p>
                      )}
                    </p>
                  </div>
                  <div className="cut__header__title2">
                    <p>Corte del día</p>
                  </div>
                  <div className="cut__header__title2">
                    <p>{formattedDate}</p>
                  </div>
                </div>

                <div className="card__body">
                  <div className="cut__column">
                    <div className="cut__row">
                      <div className="cut__column--text">
                        <p className="cut__row--title">Dinero en Caja</p>
                        <p className="cut__row--text">
                          Dinero Inicial en Caja:
                        </p>
                        <p className="cut__row--text">Ventas con Efectivo:</p>
                        <p className="cut__row--text">Abonos con Efectivo:</p>
                        <p className="cut__row--text">Entradas:</p>
                        <p className="cut__row--text">Salidas:</p>
                        <p className="cut__row--title">Efectivo total:</p>
                      </div>
                      <div className="cut__column--money">
                        <div className="cash_icon">
                          <BsCashCoin size={24} color="BLACK" />
                        </div>
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--number">
                              ${formatNumber(initialMoney)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--green">
                              ${formatNumber(currentCutData?.totalCashPaid)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}

                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--green">
                              ${formatNumber(currentCutData?.totalDebtCash)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--green">
                              ${formatNumber(currentCutData?.totalCashIn)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--red">
                              ${formatNumber(currentCutData?.totalCashOut)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--title">
                              ${formatNumber(currentCutData?.totalCashInBox)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="cut__row2">
                      <div className="cut__column--text">
                        <p className="cut__row--title">Recuento manual:</p>
                      </div>
                      <div className="cut__column--money">
                        <input
                          className="cut-input"
                          type="text"
                          value={registerCash}
                          onChange={(e) => handleInput(e, setRegisterCash)}
                          placeholder="0.00"
                          autoFocus
                          //disabled={showData}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              handleEnter();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="cut__row2">
                      <div className="cut__column--text">
                        <p className="cut__row--title">Dinero en el banco </p>
                        <p className="cut__row--text">Ventas con Tarjeta:</p>
                        <p className="cut__row--text">Abonos con Tarjeta:</p>
                        <p className="cut__row--title">Total en el banco: </p>
                      </div>
                      <div className="cut__column--money">
                        <div className="card_icon">
                          <FaRegCreditCard size={24} color="BLACK" />
                        </div>

                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--green">
                              ${formatNumber(currentCutData?.totalCardPaid)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--green">
                              ${formatNumber(currentCutData?.totalDebtCard)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--title">
                              ${formatNumber(currentCutData?.totalMoneyInBank)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="cut__column2">
                    <div className="cut__row">
                      <div className="cut__column--text">
                        <p className="cut__row--title">Ingresos</p>
                        <p className="cut__row--text">Número de Ventas:</p>
                        <p className="cut__row--text">Productos Vendidos:</p>
                        <p className="cut__row--text">Total en Efectivo:</p>
                        <p className="cut__row--text">Total en Banco:</p>
                        <p className="cut__row--text">Ventas por Apartado:</p>
                        <p className="cut__row--title">Total de Ingresos:</p>
                        <p className="cut__row--title">Ganancias Totales:</p>
                      </div>
                      <div className="cut__column--money">
                        <div className="card_icon">
                          <HiShoppingCart size={24} color="BLACK" />
                        </div>
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--number">
                              {parseInt(currentCutData?.totalSales)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--number">
                              {parseInt(currentCutData?.totalProductsSold)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--green">
                              ${formatNumber(currentCutData?.totalCashInBox)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--green">
                              ${formatNumber(currentCutData?.totalMoneyInBank)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--yellow">
                              ${formatNumber(currentCutData?.totalSalesDebt)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--title">
                              ${formatNumber(currentCutData?.totalSalesMoney)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                        {showData || true ? (
                          <div className="lock">
                            <p className="cut__row--title">
                              ${formatNumber(currentCutData?.totalGain)}
                            </p>
                          </div>
                        ) : (
                          <div className="falock-container">
                            <div className="falock">
                              <FaLock size={18} color="white" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="cut__column auto-scroll">
                    <div className="cut__row">
                      <p className="cut__row--title">Ventas por departamento:</p>
                      <div className="card_icon">
                        <GiClothes size={24} color="BLACK" />
                      </div>
                    </div>
                    <div className="cut__row">
                      <div className="cut__column--text">
                        {
                          currentCutData?.salesByDepartment.map((department, index) =>
                            <p key={`cut-department-name-${index}`} className="cut__row--text">({department?.totalProductsSold}) {department?.departmentName}</p>
                          )
                        }
                        <p className="cut__row--title">Total:</p>
                      </div>
                      <div className="cut__column--money">
                        {
                          currentCutData?.salesByDepartment.map((department, index) =>
                            <div key={`cut-department-number-${index}`} className="lock">
                              <p className="cut__row--number">
                                ${formatNumber(department?.totalSales)}
                              </p>
                            </div>
                          )
                        }
                        <p className="cut__row--title">${formatNumber(currentCutData?.salesByDepartment.reduce((acc, department) => acc + department.totalSales, 0))}</p>
                      </div>
                    </div>
                    <br />
                    <div className="cut__row">
                      <p className="cut__row--title">Ventas por departamento (Apartados):</p>
                      <div className="card_icon">
                        <GiClothes size={24} color="BLACK" />
                      </div>
                    </div>
                    <div className="cut__row">
                      <div className="cut__column--text">
                        {
                          currentCutData?.salesByDepartmentCredit.map(department =>
                            <p className="cut__row--text">({department?.totalProductsSold}) {department?.departmentName}</p>
                          )
                        }
                        <p className="cut__row--title">Total:</p>
                      </div>
                      <div className="cut__column--money">
                        {
                          currentCutData?.salesByDepartmentCredit.map(department =>
                            <div className="lock">
                              <p className="cut__row--number">
                                ${formatNumber(department?.totalSales)}
                              </p>
                            </div>
                          )
                        }
                        <p className="cut__row--title">${formatNumber(currentCutData?.salesByDepartmentCredit.reduce((acc, department) => acc + department.totalSales, 0))}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="cut__buttons">
                  <div className="cut_final_container">
                    <div className="cut__final-state">
                      {!showData ? (
                        <></>
                      ) : cutState === "exact" ? (
                        <p className="final-state__text green-style">
                          ¡CORRECTO!
                        </p>
                      ) : cutState === "missing" ? (
                        <p className="final-state__text red-style">
                          FALTANTE: $
                          {formatNumber(
                            Number(currentCutData?.totalCashInBox) - Number(total)
                          )}
                        </p>
                      ) : (
                        <p className="final-state__text blue-style">
                          SOBRANTE: $
                          {formatNumber(
                            Number(total) - Number(currentCutData?.totalCashInBox)
                          )}
                        </p>
                      )}
                    </div>
                    <div className="info-button-container">
                      {!showData ? null : (
                        <>
                          <button
                            className="info-button"
                            onClick={() => setShowMessage(!showMessage)}
                          >
                            <FaInfoCircle color="gray" />
                          </button>
                          {showMessage && (
                            <span className="info-message">
                              Dinero inicial en caja + Ingreso de Efectivo -
                              Dinero en caja
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    {!showData && (
                      <p className="cut-instructions">
                        Ingrese el recuento manual y presione enter antes de
                        realizar el corte.
                      </p>
                    )}
                  </div>
                  <button
                    className={`cut-button ${showData ? "golden-button" : "silver-button"
                      }`}
                    onClick={saveCut}
                  >
                    {" "}
                    Hacer corte
                  </button>
                </div>
              </div>
            ) : selectedOption === 1 ? (
              <div className="cut__card-history">
                <div className="cut-table">
                  <div className="cut-row cut-header">
                    <div className="cut-cell cut-header-cell">Fecha</div>
                    <div className="cut-cell cut-header-cell">Usuario</div>
                    <div className="cut-cell cut-header-cell">Total</div>
                    <div className="cut-cell cut-header-cell">Resultado</div>
                  </div>
                  {cutRegisters.map((register) => (
                    <div
                      className="cut-row"
                      onClick={() => setSelectedCut(register)}
                    >
                      <div className="cut-cell">
                        {formatDateTime(register.created_at)}
                      </div>
                      <div className="cut-cell">
                        {register.user_name ? register.user_name : "N/A"}
                      </div>
                      <div className="cut-cell">
                        ${formatNumber(register.total)}
                      </div>
                      <div className="cut-cell">
                        {register.missing > 0
                          ? `Faltante: $${formatNumber(register.missing)}`
                          : register.spare > 0
                            ? `Sobrante: $${formatNumber(register.spare)}`
                            : "Exacto"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="cut-download-container">
                <div className="cut-download-container2">
                  <p className="cut-download-description">
                    Seleccione el mes y el año para exportar los datos
                    correspondientes en un archivo de EXCEL.
                  </p>
                </div>
                <h3 className="cut-download-text">Seleccionar Mes y Año:</h3>
                <DatePicker
                  selected={selectedDate[0]}
                  onChange={handleDateChange}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  showFourColumnMonthYearPicker
                  locale="es"
                />
                {!isLoadingExcel ? (
                  <button
                    className="cut-download-button"
                    onClick={handleDownload}
                  >
                    <RiFileExcel2Fill />
                    Descargar datos
                  </button>
                ) : (
                  <CirclesWithBar
                    height="100"
                    width="100"
                    color="#ffd700"
                    outerCircleColor="#d1b104"
                    innerCircleColor="#ffd700"
                    barColor="#ffd700"
                    ariaLabel="circles-with-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <CutDetailModal
        isOpen={selectedCut}
        onClose={closeModal}
        cutData={selectedCut}
      />
      <CutDetailModal
        isOpen={selectedCut}
        onClose={closeModal}
        cutData={selectedCut}
      />
    </>
  );
};

export default Cut;
