import { MdAddBox, MdClose, MdFilterAltOff } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import BACK_END from "../../../config/direction";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { FaUser } from "react-icons/fa6";
import { BsCalendar3 } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import Calendar from "react-calendar";
import Dropdown from "react-dropdown";
import "./InventoryEntries.css";
import axios from "axios";
import { exportToExcel } from "../../../util/excel";
import { toast } from "react-toastify";

import { useContext } from 'react';
import { SessionContext } from "../../../hooks/useSession";

const InventoryEntries = () => {
  const { token } = useContext(SessionContext);

  const [searchEntry, setSearchEntry] = useState("");

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [rangeDate, setRangeDate] = useState(null);

  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [registers, setRegisters] = useState([]);

  const navigate = useNavigate();

  const weekdayLabels = ["D", "L", "M", "M", "J", "V", "S"];

  const cleanFilters = () => {
    setSearchEntry("");
    setRangeDate(null);
    setSelectedUser(null);
  };

  // Function to get filtered data from the backend
  const getFilteredData = () => {
    const searchTerm = searchEntry.trim();
    const selectedUserId = selectedUser?.value || "";

    const startDate = rangeDate ? rangeDate[0].toISOString() : null;
    const endDate = rangeDate ? rangeDate[1].toISOString() : null;

    axios
      .post(`${BACK_END}/entry/filter/0`, {
        searchTerm,
        userId: selectedUserId,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        const { data } = res.data;
        setRegisters(data);
      })
      .catch((error) => {
        console.error("Error fetching filtered data:", error);
      });
  };

  // useEffect to fetch filtered data
  useEffect(() => {
    getFilteredData(); // eslint-disable-next-line
  }, [searchEntry, rangeDate, selectedUser]);

  // Initialize
  useEffect(() => {

    // Get brands
    axios.get(`${BACK_END}/user/0`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      const data = res.data;
      if (res.status === 200) {
        const dataPackage = data.map((user) => ({
          label: user.username,
          value: user.user_id,
        }));
        setUserOptions(dataPackage);
      }
    }).catch((error) => {
      toast.error('No se pudo recuperar a los usuarios.');
    });

    // Get products
    axios.get(`${BACK_END}/entry/0`)
      .then((res) => {
        const { data } = res.data;
        if (res.status === 200) {
          setRegisters(data);
        }
      });
  }, [token]);

  // Function to format date
  const formatDate = (isoDate) => {
    if (!isoDate) {
      return null;
    }

    const date = new Date(isoDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    return date.toLocaleDateString("es-MX", options);
  };

  // Add a new entry
  const handleClick = (entry_id) => {
    navigate(`/add_entry/${entry_id}`);
  };

  const handleExportDataToExcel = () => {
    const searchTerm = searchEntry.trim();
    const selectedUserId = selectedUser?.value || "";

    const startDate = rangeDate ? rangeDate[0].toISOString() : null;
    const endDate = rangeDate ? rangeDate[1].toISOString() : null;

    axios
      .post(`${BACK_END}/entry/filter-details`, {
        searchTerm,
        userId: selectedUserId,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        const { data } = res.data;
        const formattedData = data.map((item) => ({
          Fecha: new Date(item.created_at),
          "Folio de entrada": item.entry_detail_id,
          Código: item.product_id,
          Artículo: item.name,
          Costo: Number(item.price),
          Cantidad: parseInt(item.quantity),
          "Costo total": Number(item.price) * parseInt(item.quantity),
        }));
        exportToExcel(formattedData, `Entradas`);
      })
      .catch((error) => {
        toast.error("Error recuperando la información.", {
          autoClose: 2000,
          hideProgressBar: true,
        });
        console.error("Error fetching filtered data:", error);
      });
  };

  const formatNumber = (number) => {
    number = Number(number);
    return number.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="inventory-container">
      <div className="table-box-container-topborderless">
        <div className="table-box">
          <div className="table">
            <div className="table-header">
              <div className="inventory-header">Descripción</div>
              <div className="inventory-header">Fecha</div>
              <div className="inventory-header display-none-phone">Registrado por</div>
              <div className="inventory-header">Costo Total</div>
              <div className="inventory-header">Total</div>
            </div>
            <div className="exits-tbody">
              {
                // Products' list
                registers.map((product, productIndex) => (
                  <div
                    className={"exits-row"}
                    key={`entry-${productIndex}`}
                    onClick={() => handleClick(product?.entry_id)}
                  >
                    <div className="exits-item-row">
                      <div className="exits-item">{product?.description}</div>
                      <div className="exits-item">{formatDate(product?.entry_date)}</div>
                      <div className="exits-item display-none-phone">{product?.user_name}</div>
                      <div className="exits-item">${formatNumber(product?.total)}</div>
                      <div className="exits-item">{product?.total_products}</div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className="inventory-row-bottom">
        <div className="buttons-row-container2">
          <button
            className="add-product-button"
            onClick={() => navigate("/add_entry")}
          >
            <MdAddBox size={28} />
            <span className="date-entry-label">Nueva compra</span>
          </button>
          <div className="inventory-search-bar-product">
            <FaSearch className="search-icon" />
            <input
              type="text"
              value={searchEntry}
              onChange={(event) => setSearchEntry(event.target.value)}
              placeholder="Descripción"
              autoFocus={!isMobile}
            />
          </div>
        </div>
        <div className="buttons-row-container2">
          <Dropdown
            className="inventory-department-scroll"
            options={userOptions}
            onChange={(text) => setSelectedUser(text)}
            value={selectedUser}
            placeholder={
              <div className="dropdown-placeholder">
                <FaUser className="dropdown-icon" color="black" />
                Usuario
              </div>
            }
          />
          <div className="date-entry-container">
            <button
              className="date-entry-button"
              onClick={() => setShowDatePicker(!showDatePicker)}
            >
              <BsCalendar3 size={20} />
              <span className="date-entry-label">Fecha</span>
            </button>
            <div className="bottom-corner-buttons">
              <button className="bottom-button" onClick={cleanFilters}>
                <MdFilterAltOff size={20} color="black" />
              </button>
            </div>
            <button
              className="bottom-button inventory-export-button"
              onClick={handleExportDataToExcel}
            >
              <RiFileExcel2Fill size={18} /> Exportar
            </button>
            {showDatePicker && (
              <div className="date-modal-overlay">
                <div className="date-picker-container">
                  <Calendar
                    tileClassName={({ date, view }) => {
                      // Agregar clase de estilo condicional para resaltar el día actual
                      if (
                        view === "month" &&
                        date.toDateString() === new Date().toDateString()
                      ) {
                        return "highlight-today";
                      }
                      return "";
                    }}
                    onChange={(date) => setRangeDate(date)}
                    value={rangeDate}
                    selectRange={true}
                    showNeighboringMonth={false}
                    formatShortWeekday={(locale, date) =>
                      weekdayLabels[date.getDay()]
                    }
                  />
                  <button
                    className="calendar-close-button"
                    onClick={() => setShowDatePicker(false)}
                  >
                    <MdClose size={20} color="black" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryEntries;
