import UtilityContainer from '../UtilityContainer/UtilityContainer';
import React, { useState, useEffect } from 'react';
import BACK_END from '../../../config/direction';
import { PieChart } from '@mui/x-charts';
import Paper from "@mui/material/Paper";
import axios from 'axios';
import { exportToExcel } from '../../../util/excel';

import { useContext } from 'react';
import { SessionContext } from '../../../hooks/useSession';

const UtilityOrigin = () => {
    const { token } = useContext(SessionContext);

    const [dataset, setDataset] = useState([]);

    // Initial fetch
    const getData = () => {
        axios.get(`${BACK_END}/stadistics/customer-origins`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then((res) => {
                const data = res.data;
                if (res.status === 200) {

                    const formattedData = data.map((element, index) => {
                        return ({ id: index.toString(), value: Number(Number(element?.percentage).toFixed(2)), label: `${element?.originName}`, totalCustomers: element?.totalCustomers });
                    });

                    const yChart = [{
                        arcLabel: (item) => (item.value > 0) ? `${item.value}%` : '',
                        data: formattedData,
                        innerRadius: 70,
                    }];

                    setDataset(yChart);
                }
            })
            .catch((error) => console.error(error));
    }

    // eslint-disable-next-line
    useEffect(getData, []);

    const handleExportClick = () => {
        let data = [];

        dataset[0].data.forEach(row => {
            if (row.month !== '') {
                data.push({ 'Origen': row?.label, 'Porcentaje': row?.value + '%', 'Clientes totales': row?.totalCustomers });
            }
        });

        exportToExcel(data, 'Análisis de marketing');
    };

    // Container header
    const header = (
        <div className='utility-header'>
            Análisis de marketing

        </div>
    );

    // Custom Tooltip
    const CustomItemTooltipContent = (props) => {
        const { series, dataIndex, axisValue } = props;
        return (
            <Paper sx={{ padding: 1, backgroundColor: 'white' }}>
                <p>{axisValue}</p>
                <p>$ {series[0]?.data[dataIndex].toFixed(2)}</p>
            </Paper>
        );
    };

    const chart = (
        <div className='utility-payment-content chart-content-father'>
            {
                (dataset.length > 0)
                    ?
                    <PieChart
                        sx={{
                            width: "100%",
                            maxWidth: 600,
                            maxHeight: 300,
                        }}
                        margin={{
                            right: 160,
                        }}
                        series={dataset}
                        width={600}
                        height={300}
                        tooltip={{
                            axisContent: CustomItemTooltipContent,
                            itemContent: CustomItemTooltipContent,
                        }}
                    />
                    :
                    <></>
            }
        </div>
    );

    return (
        <UtilityContainer
            header={header}
            content={chart}
            handleExportClick={handleExportClick}
        />
    )
}

export default UtilityOrigin;