import { FaSearch, FaList } from "react-icons/fa";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { MdFilterAltOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BACK_END from "../../../config/direction";
import { PiTagSimpleFill } from "react-icons/pi";
import { MdAddBox } from "react-icons/md";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import axios from "axios";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useContext } from "react";
import { SessionContext } from "../../../hooks/useSession";
import * as XLSX from "xlsx";
import "./InventoryMain.css";
import { toast } from "react-toastify";
import { format } from "date-fns";

const InventoryMain = () => {
  const { userData } = useContext(SessionContext);
  const isAdmin = userData && userData.rol === "Administrador";
  const [searchProduct, setSearchProduct] = useState("");

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [totalCost, setTotalCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalStock, setTotalStock] = useState(0);

  const navigate = useNavigate();

  const formatNumber = (number) => {
    number = Number(number);
    return number.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  // Function to toggle between table and grid view
  const toggleViewMode = () => {
    setViewMode(viewMode === "table" ? "grid" : "table");
  };

  const [viewMode, setViewMode] = useState(
    window.innerWidth > 600 ? "table" : "grid"
  );

  useEffect(() => {
    // Función para actualizar el viewMode basado en el tamaño de la ventana
    const handleResize = () => {
      setViewMode(window.innerWidth > 768 ? "table" : "grid");
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setProductsLoading(true);

    // Get departments
    axios.get(`${BACK_END}/department`).then((res) => {
      const data = res.data.data;
      if (res.status === 200) {
        const dataPackage = data.map((department) => ({
          label: department.name,
          value: department.id,
        }));
        setDepartmentOptions([{ label: 'Cualquiera*', value: null }, ...dataPackage]);
      }
    });

    // Get products
    axios.get(`${BACK_END}/product/0`).then((res) => {
      const data = res.data;
      if (res.status === 200) {
        setProductsLoading(false);
        setProducts(data);
      }
    });
  }, []);

  useEffect(() => {
    axios.get(`${BACK_END}/product/total?department_id=${selectedDepartment?.value}`).then((res) => {
      const { data } = res
      setTotalCost(data[0].totalCost);
      setTotalPrice(data[0].totalPrice);
      setTotalStock(data[0].totalStock);

    })
  }, [selectedDepartment]);

  // Filter event
  useEffect(() => {
    // Get products
    axios
      .post(`${BACK_END}/product/filter/0`, {
        name: searchProduct,
        department_id: selectedDepartment?.value,
      })
      .then((res) => {
        const data = res.data;
        if (res.status === 200) {
          setProducts(data);
        }
      });
  }, [searchProduct, selectedDepartment]);

  const cleanFilters = () => {
    setSearchProduct("");
    setSelectedDepartment(null);
  };

  const handleEdit = (product_id) => {
    navigate(`/edit_product/${product_id}`);
  };

  const exportToExcel = async () => {
    try {
      const queryParams = selectedDepartment
        ? `department_id=${selectedDepartment.value}`
        : "";
      const res = await axios.get(
        `${BACK_END}/product/download?${queryParams}`
      );
      if (res.status === 200) {
        const allProducts = res.data;

        // Prepare data for Excel
        const data = allProducts.map((product) => ({
          Código: product?.product_id,
          Nombre: product?.name,
          Costo: Number(product?.cost),
          Precio: Number(product?.price),
          Stock: parseInt(product?.stock),
          Departamento: product?.departmentName,
        }));

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Inventario");

        // Generate filename with current date
        const currentDate = format(new Date(), "dd-MM-yyyy-HHmmss");
        const filename = `inventario_${currentDate}.xlsx`;

        // Save to file
        XLSX.writeFile(wb, filename);

        toast.success("Archivo descargado.", {
          hideProgressBar: true,
          autoClose: 1500,
        });
      }
    } catch (error) {
      toast.error("Error al descargar archivo.", {
        hideProgressBar: true,
        autoClose: 200,
      });
      console.error("Error exporting data to Excel:", error);
    }
  };

  return (
    <div className="inventory-container">
      <div
        className={`table-box-container ${viewMode === "table" && products.length > 0 ? "table-box-top-hidden" : ""
          }`}
      >
        <div className="table-box">
          {viewMode === "table" ? (
            <table className="table">
              {!productsLoading && products && (
                <thead>
                  <tr className="table-header">
                    {/* Columnas */}
                    {!isMobile && <th className="inventory-header">CÓDIGO</th>}
                    <th className="inventory-header">NOMBRE</th>
                    {!isMobile && <th className="inventory-header">COSTO</th>}
                    {!isMobile && <th className="inventory-header">PRECIO</th>}
                    {!isMobile && <th className="inventory-header">STOCK</th>}
                    {!isMobile && (
                      <th className="inventory-header">DEPARTAMENTO</th>
                    )}
                    {isMobile && <th className="inventory-header">IMAGEN</th>}
                    {/* Columnas */}
                  </tr>
                </thead>
              )}
              <tbody>
                {
                  // Products' list
                  products.map((product, productIndex) => (
                    <tr
                      className={
                        isMobile ? "product-row-mobile" : "product-row"
                      }
                      key={productIndex}
                      onClick={() => handleEdit(product.product_id)}
                    >
                      {!isMobile && <td>{product.product_id}</td>}
                      <td>
                        <div>{product.name}</div>
                      </td>

                      {!isMobile && <td>${formatNumber(product.cost)}</td>}
                      {!isMobile && <td>${formatNumber(product.price)}</td>}
                      {!isMobile && <td>{product.stock}</td>}
                      {!isMobile && <td>{product.departmentName}</td>}

                      {isMobile && (
                        <td>
                          <img
                            className="product-image"
                            src={`${BACK_END}/product/image/${product?.image ?? "default-image.png"
                              }`}
                            alt="Producto"
                            loading="lazy"
                            height={100}
                          />
                        </td>
                      )}
                    </tr>
                  ))

                }

              </tbody>

            </table>
          ) : (
            <div className="grid-box">
              <div className="grid-container">
                {products.map((product) => (
                  <div
                    className="grid-item"
                    key={product.product_id}
                    onClick={() => handleEdit(product.product_id)}
                  >
                    <div className="grid-item-img-container">
                      <img
                        src={`${BACK_END}/product/image/${product?.image ?? "default-image.png"
                          }`}
                        alt="Producto"
                        loading="lazy"
                      />
                    </div>
                    <p>{product.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="totals-container">
        <div className="totals-item"></div>
        <div className="totals-item">TOTALES:</div>
        <div className="totals-item">$<span id="totalCost">{formatNumber(totalCost)}</span></div>
        <div className="totals-item">$<span id="totalPrice">{formatNumber(totalPrice)}</span></div>
        <div className="totals-item"><span id="totalStock">{totalStock}</span></div>
        <div className="totals-item"></div>
      </div>

      <div>

      </div>
      <div className="inventory-row-bottom2">
        {isAdmin && <button
                className="add-product-button"
                onClick={() => navigate("/add_product")}
              >
                <MdAddBox size={28} />
                <span className="add-product-label">Agregar producto</span>
              </button>}

        <div className="inventory-search-bar-product">
          <FaSearch className="search-icon" />
          <input
            type="text"
            onChange={(event) => setSearchProduct(event.target.value)}
            placeholder="Nombre o código"
            autoFocus={!isMobile}
          />
        </div>

        <Dropdown
          className="inventory-department-scroll"
          options={departmentOptions}
          onChange={(text) => setSelectedDepartment(text)}
          value={selectedDepartment}
          placeholder={
            <div className="dropdown-placeholder">
              <PiTagSimpleFill className="dropdown-icon" color="black" />
              Departamento
            </div>
          }
        />
        <div className="bottom-corner-buttons">
          <button className="bottom-button" onClick={cleanFilters}>
            <MdFilterAltOff size={20} color="black" />
          </button>
          <button className="bottom-button" onClick={toggleViewMode}>
            {viewMode === "table" ? (
              <FaList size={20} color="black" />
            ) : (
              <BsFillGrid3X3GapFill size={20} color="black" />
            )}
          </button>
          {isAdmin && (
            <button
              className="bottom-button inventory-export-button"
              onClick={exportToExcel}
            >
              <RiFileExcel2Fill size={18} /> Exportar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventoryMain;
